import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import {useStaticQuery, graphql} from "gatsby"
import Layout from "../components/layout";
import Grid from "@material-ui/core/Grid";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Img from 'gatsby-image'
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },

    root: {
        padding: theme.spacing(5, 0, 1, 1),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2, 2),
        }
    },
    title: {
        fontFamily: 'Helvetica Neue, sans-serif, serif',
        fontSize: '2.2vw',
        fontWeight: 'bold',
        color: 'seashell',
        textShadow: '1px 1px 150px #080705',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '4vh',
            margin: '2rem 0 0 0 ',
        }
    },

    media: {
        height: 'fit-content',
        width:'100%',
        [theme.breakpoints.down('xs')]: {
            height: 250,
        },
        [theme.breakpoints.down('sm')]: {
            height: '100%',
        },
    },
    paragraphTitle: {
        fontFamily: 'Helvetica Neue, sans-serif, serif',
        fontSize: '2.2rem',
        fontWeight: 'bold',
        color: '#f2f2f2',
        textAlign: 'left',
        marginTop: '2rem',
        marginBottom:'2rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '4vw',
            lineHeight: '3rem',
            marginTop: '1rem',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '4vw',
            lineHeight: '1.5',
            marginTop: '0rem',
        },
    },

    link:{
        fontFamily: 'Helvetica Neue, sans-serif, serif',
        fontSize: '1.25rem',
        fontWeight: 'bold',
        // textDecoration: 'none',
        color: '#dce1e3',
        '&:hover': {
            color: 'goldenrod',
            '&::after': {
                content: '27F6',
                display: 'block',
                marginTop: 'auto',
                fontSize: '1.2rem',
            }
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '3vw',
        },
    },
    paragraph: {
        fontFamily: 'Helvetica Neue, sans-serif, serif',
        fontSize: '1.5rem',
        padding: '1rem',
        // marginTop: '',
        lineHeight: '2.5rem',
        color: '#f2f2f2',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            fontSize: '2.5vw',
            marginTop: '1rem',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.5vh',
            lineHeight: '1.2',
        },
    },

    otherContents: {
        color: '#f2f2f2',
        fontSize: '1.8rem',
        marginTop: '3rem',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.8vh',
            lineHeight: '2rem',
        },
    },

    icon: {
        color: 'white',
        fontSize: '1.7rem',
        margin: theme.spacing(3),
        border: '1px solid',
        '&:hover': {
            color: 'goldenrod',
            background: 'rgba(0,0,0,0) 0%',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
    },
    contents:{
        width:'100%',
        margin:'1rem',
        border:'1px solid white',
        boxShadow: '0 10px 25px 0 rgb(133 191 186 / 50%)',

        [theme.breakpoints.down('sm')]: {
            margin: '0 0 1rem 0'
        },
    },
    url:{
        fontSize:'1rem',
    }
}));

export default function Schedule() {
    const classes = useStyles();
    const data = useStaticQuery(graphql`
        query{
            placeholderImage: file(relativePath: {eq: "230819_live.png"}) {
                childImageSharp {
                    fluid(quality: 65, maxWidth: 1100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            },
        }`
    );

    return (
        <Layout>
            <Container maxWidth="lg" component="main" className={classes.root}>
                <Typography className={classes.title} gutterBottom variant="h5" component="h2">
                    Schedule
                </Typography>
                <Grid container className={classes.contents} spacing={5}>
                    <Grid item xs={12} md={12}>
                        <Typography gutterBottom variant="p" className={classes.paragraphTitle} component="p">
                            【日時】2024年10月26日(土) 開場17:00 開演 17:30<br/>
                        </Typography>
                        <Typography gutterBottom variant="p" className={classes.paragraphTitle} component="p">
                            【イベント名】Peace of Light<br/>
                        </Typography>
                        <Typography gutterBottom variant="p" className={classes.paragraph} component="p">
                            【会場】上野公園水上音楽堂野外ステージ <a href={'https://ueno.or.jp/peaceoflight/'}>( https://ueno.or.jp/peaceoflight/ )</a><br/>
                            【チケット】500円<br/>
                        </Typography>
                        {/*<Img fluid={data.placeholderImage.childImageSharp.fluid} className={classes.placeholder}/>*/}
                    </Grid>
                </Grid>
                <Grid container className={classes.contents} spacing={5}>
                    <Grid item xs={12} md={12}>
                        <Typography gutterBottom variant="p" className={classes.paragraphTitle} component="p">
                            【日時】2024年11月24日(土) 開場18:00 開演18:30<br/>
                        </Typography>
                        <Typography gutterBottom variant="p" className={classes.paragraphTitle} component="p">
                            【イベント名】okapiとAoi MichelEダブルレコ発ライブ<br/>
                        </Typography>
                        <Typography gutterBottom variant="p" className={classes.paragraph} component="p">
                            【会場】錦糸町rebirth ( 東京都墨田区江東橋4-30-16 メンテック大塚ビル B1 )<br/>
                            【概要】同時期にアルバムを発表のソロギタリスト2人によるダブルレコ発ライブ<br/>
                            【出演】okapi / Aoi MichelE<br/>
                            【チケット】前売り 4000円 当日4500円<br/>
                            【ご予約】ご予約はこちらからお願いいたします。<br/>
                            <a className={classes.url} href="https://okapi-music.com/reserve/?post_id=2217" target="_blank">
                                https://okapi-music.com/reserve/?post_id=2217
                            </a><br/>
                            こちらまでご予約名、人数をお送りください。
                        </Typography>
                        {/*<Img fluid={data.placeholderImage.childImageSharp.fluid} className={classes.placeholder}/>*/}
                    </Grid>
                </Grid>
                <Grid container className={classes.contents} spacing={5}>
                    <Grid item xs={12} md={12}>
                        <Typography gutterBottom variant="p" className={classes.paragraphTitle} component="p">
                            【日時】2025年1月11日(土) 開場18:30 開演19:00<br/>
                        </Typography>
                        <Typography gutterBottom variant="p" className={classes.paragraphTitle} component="p">
                            【イベント名】指弾きの日 Live Vol.15 ~A Day of Finger Style~<br/>
                        </Typography>
                        <Typography gutterBottom variant="p" className={classes.paragraph} component="p">
                            【会場】国分寺GIVE HERATS ( 国分寺市 本多 2-1-4 )<br/>
                            【【チケット】前売り5500円 当日6600円 ＋1ドリンク
                            【出演】西山隆行 / 黒田晃年 /Aoi MichelE /門馬由哉
                            【ご予約】ご予約、ご購入はこちらまでメールをお願いいたします。
                            <a className={classes.url} href="mailto:musictail@me.com" target="_blank">
                                musictail@me.com
                            </a><br/>
                            ※チケット申込＆購入順で入場整理番号を発行
                        </Typography>
                        {/*<Img fluid={data.placeholderImage.childImageSharp.fluid} className={classes.placeholder}/>*/}
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    );
}

